import { SvgIcon, SvgIconProps } from "@peersyst/react-components";
import { cx } from "@peersyst/react-utils";
export default function WalletIcon({ className, ...rest }: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...rest} data-testid="WalletIcon" className={cx(undefined, "Icon", className)} fill="none">
            <path
                d="M20.8 9.1875V6.6C20.8 5.33988 20.8 4.70983 20.5602 4.22852C20.3493 3.80516 20.0127 3.46095 19.5988 3.24524C19.1282 3 18.5121 3 17.28 3H4.52C3.28789 3 2.67183 3 2.20122 3.24524C1.78726 3.46095 1.45071 3.80516 1.23979 4.22852C1 4.70982 1 5.33988 1 6.6V17.4C1 18.6601 1 19.2902 1.23979 19.7715C1.45071 20.1948 1.78726 20.539 2.20122 20.7548C2.67183 21 3.28788 21 4.52 21L17.28 21C18.5121 21 19.1282 21 19.5988 20.7548C20.0127 20.539 20.3493 20.1948 20.5602 19.7715C20.8 19.2902 20.8 18.6601 20.8 17.4V14.8125M15.3 12C15.3 11.4773 15.3 11.2159 15.3423 10.9985C15.5159 10.106 16.1981 9.40827 17.0708 9.23073C17.2833 9.1875 17.5389 9.1875 18.05 9.1875H20.25C20.7611 9.1875 21.0167 9.1875 21.2292 9.23073C22.1019 9.40827 22.7841 10.106 22.9577 10.9985C23 11.2159 23 11.4773 23 12C23 12.5227 23 12.7841 22.9577 13.0015C22.7841 13.894 22.1019 14.5917 21.2292 14.7693C21.0167 14.8125 20.7611 14.8125 20.25 14.8125H18.05C17.5389 14.8125 17.2833 14.8125 17.0708 14.7693C16.1981 14.5917 15.5159 13.894 15.3423 13.0015C15.3 12.7841 15.3 12.5227 15.3 12Z"
                stroke="#008CFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
