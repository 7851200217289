import { SvgIcon, SvgIconProps } from "@peersyst/react-components";
import { cx } from "@peersyst/react-utils";
export default function CopyIcon({ className, ...rest }: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...rest} data-testid="CopyIcon" className={cx(undefined, "Icon", className)} fill="none">
            <path
                d="M10.35 1.00308C9.60744 1.01314 9.16167 1.05613 8.80122 1.23979C8.38726 1.45071 8.05071 1.78726 7.83979 2.20122C7.65613 2.56167 7.61314 3.00744 7.60308 3.75M20.25 1.00308C20.9926 1.01314 21.4383 1.05613 21.7988 1.23979C22.2127 1.45071 22.5493 1.78726 22.7602 2.20122C22.9439 2.56167 22.9869 3.00744 22.9969 3.74999M22.9969 13.65C22.9869 14.3926 22.9439 14.8383 22.7602 15.1988C22.5493 15.6127 22.2127 15.9493 21.7988 16.1602C21.4383 16.3439 20.9926 16.3869 20.25 16.3969M23 7.59999V9.79999M14.2001 1H16.4M4.52 23H12.88C14.1121 23 14.7282 23 15.1988 22.7602C15.6127 22.5493 15.9493 22.2127 16.1602 21.7988C16.4 21.3282 16.4 20.7121 16.4 19.48V11.12C16.4 9.88788 16.4 9.27183 16.1602 8.80122C15.9493 8.38726 15.6127 8.05071 15.1988 7.83979C14.7282 7.6 14.1121 7.6 12.88 7.6H4.52C3.28788 7.6 2.67183 7.6 2.20122 7.83979C1.78726 8.05071 1.45071 8.38726 1.23979 8.80122C1 9.27183 1 9.88788 1 11.12V19.48C1 20.7121 1 21.3282 1.23979 21.7988C1.45071 22.2127 1.78726 22.5493 2.20122 22.7602C2.67183 23 3.28788 23 4.52 23Z"
                stroke="#008CFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
