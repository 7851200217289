enum Queries {
    USER = "user",
    NFTS = "nfts",
    MY_NFTS = "my-nfts",
    DROP_NFTS = "drop-nfts",
    NFT = "nft",
    NFT_DRAFT = "nft-draft",
    COLLECTION = "collection",
    COLLECTIONS = "collections",
    NAME_AVAILABILITY = "name-availability",
    PUBLISH_NFT = "publish-nft",
    TRENDS = "trends",
    DROP = "drop",
    DROPS = "drops",
    COLLECTION_IS_DROP = "collection-is-drop",
    COLLECTION_NAME_AVAILABILITY = "collection-name-availability",
    CHECK_BALANCE = "check-balance",
    MAKE_NFT_OFFER = "make-nft-offer",
    BUY_NFT = "buy-nft",
    GET_TX_PAYLOAD = "get-tx-payload",
    NFT_AUCTION = "nft-auction",
}

export default Queries;
